import { get } from 'lodash';
import { I$W } from '@wix/yoshi-flow-editor';
import { BLOG_POST_PAGE_SECTION_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';
import { createPerformanceTracker } from '@wix/communities-blog-client-common';
import { AdapterControllerFn } from '../../common/controller/editor-adapter';
import {
  createControllerId,
  createLogger,
  doRouting,
  getSectionPathname,
  handleLocationChange,
  isFrameless,
} from '../../common/controller/helpers';
import { initLazyActions } from '../../common/controller/lazy-actions';
import { setRouterMatch } from '../../common/router';
import { createMonitoring } from '../../common/services/create-monitoring';
import { isInPostPreview } from '../../common/services/detect-route';
import { getCurrentSiteLanguage } from '../../common/services/get-current-site-language';
import getEnvironment from '../../common/services/get-environment';
import { handleProvisioning } from '../../common/services/handle-provisioning';
import { isRtlLanguage } from '../../common/services/is-rtl-language';
import listenToInstanceChange from '../../common/services/listen-to-instance-change';
import listenToSettingsChange from '../../common/services/listen-to-settings-change';
import { simulateControllerError } from '../../common/services/simulate-error';
import { subscribeToChange } from '../../common/services/state-optimizer';
import { getInitialStateVersions } from '../../common/services/state-optimizer/change-detector';
import { setAppSettings } from '../../common/store/app-settings/app-settings-actions';
import { AppStore } from '../../common/types';
import reducers from '../reducers';
import { getSectionUrl } from '../services/get-section-url';
import { getConnections } from './connections';
import { createPostPageApi } from './create-post-page-api';
import { createReduxStore } from './create-redux-store';
import { createRouter } from './create-router';
import {
  initializeActions,
  initializePromisifiedActions,
  initializeStoreBaseData,
  refreshDataOnLogin,
} from './init-actions';

const isProduction = process.env.NODE_ENV === 'production';

export const createPostPageController: AdapterControllerFn = (
  controllerConfig,
  allCtrls,
  context,
  flowAPI,
) => {
  const { appParams, compId, config, setProps, wixCodeApi, platformAPIs } =
    controllerConfig;
  const { isEditor, isPreview, isSSR, isDebug } = getEnvironment(wixCodeApi);

  const { sentry, fedopsLogger } = createMonitoring({ flowAPI });

  const language = getCurrentSiteLanguage(wixCodeApi, flowAPI);

  const bundleName = 'post-page';

  const log = createLogger(isDebug, isProduction);
  const perf = createPerformanceTracker(bundleName, { isDebug, isSSR });

  log('createPostPageController', {
    appParams,
    allCtrls,
    wixCodeApi,
    isSSR,
    language,
  });

  let store: AppStore;

  const pageReady = async () => {
    const pageReadyMarker = perf.trackStart(
      `${new Date().toISOString().slice(11)} pageReady`,
    );
    log('createPostPageController.pageReady -> start');

    const connections = getConnections(controllerConfig.$w);

    const sectionUrl = await getSectionUrl({
      wixCodeApi,
      instanceId: appParams.instanceId,
      sectionId: BLOG_POST_PAGE_SECTION_ID,
    });

    simulateControllerError(wixCodeApi, 'post-page.pageReady');

    initLazyActions({ isSSR });

    let marker = perf.trackStart('createReduxStore', pageReadyMarker);
    store = createReduxStore({
      appParams,
      wixCodeApi,
      compId,
      reducers,
      fedopsLogger,
      getRouter: () => router,
      platformAPIs,
      isSSR,
      sentry,
      isEditor,
      isPreview,
      language,
      bundleName,
      flowAPI,
    });
    perf.trackEnd(marker);

    marker = perf.trackStart('initializeActions', pageReadyMarker);
    const actions = initializeActions({ controllerConfig, store, flowAPI });
    const actionsPromisified = initializePromisifiedActions({ store });
    perf.trackEnd(marker);

    marker = perf.trackStart('createRouter', pageReadyMarker);

    const isCustomUrlEnabled = !isEditor;
    const router = createRouter(
      store,
      config,
      wixCodeApi,
      isCustomUrlEnabled,
      connections,
    );
    perf.trackEnd(marker);

    const matchedRoute = await router.matchPath({
      pathname: getSectionPathname({ store, wixCodeApi, sectionUrl }),
    });
    const route = get(matchedRoute, 'route');

    await perf.trackPromise(
      'initializeStoreBaseData',
      () =>
        initializeStoreBaseData({
          wixCodeApi,
          store,
          language,
          platformAPIs,
          config,
          bundleName,
          translationsName: 'main',
          preFetch: () =>
            router.preFetch(
              getSectionPathname({ store, wixCodeApi, sectionUrl }),
            ),
          allowPreviewInstance: isInPostPreview(route),
          appParams,
          route,
        }),
      pageReadyMarker,
    );
    listenToInstanceChange(wixCodeApi, appParams, store);

    handleLocationChange({
      wixCodeApi,
      store,
      log,
      bundleName,
      router,
      sectionUrl,
    });
    await perf.trackPromise(
      'doRouting',
      () =>
        doRouting({
          store,
          router,
          pathname: getSectionPathname({ store, wixCodeApi, sectionUrl }),
          isInitialLoad: true,
        }),
      pageReadyMarker,
    );

    log('createPostPageController.pageReady -> done');
    const state = store.getState();

    // after initial routing is done, we subscribe to get routing change as fast as route changes, without waiting for handler to resolve
    router.onMatch((match: any) => store.dispatch(setRouterMatch(match)));

    const stateVersions = getInitialStateVersions(state);
    const controllerId = createControllerId();

    setProps({
      state,
      stateVersions,
      actions,
      actionsPromisified,
      isSSR,
      isRTL: isRtlLanguage(language),
      controllerId,
      fitToContentHeight: true,
    });

    if (
      (isEditor || isPreview) &&
      !isFrameless({ state: store.getState(), wixCodeApi })
    ) {
      listenToSettingsChange(store);
    }

    refreshDataOnLogin({ wixCodeApi, store, router });
    subscribeToChange(store, stateVersions, setProps, controllerId);
    perf.trackEnd(pageReadyMarker);
  };

  return {
    pageReady: () =>
      isEditor
        ? handleProvisioning(
            appParams,
            fedopsLogger,
            wixCodeApi,
            setProps,
            pageReady,
            flowAPI,
          )
        : pageReady(),
    exports: () => createPostPageApi(appParams.instance, wixCodeApi, flowAPI),
    updateConfig: (_$w: I$W, { style: { styleParams } }) => {
      store && store.dispatch(setAppSettings({ style: styleParams }));
    },
  };
};
